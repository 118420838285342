<template>
    <b-modal
        v-bind="$attrs"
        id="create-users-modal"
        ref="create-user"
        centered
        :title="
            $t('common.crud.newResource', {
                resource: $tc('modules.users.description', 1),
            })
        "
        hideFooter
        @show="setAttributesDefaultValues"
    >
        <div class="ml-2 mr-2 mt-2">
            <formulateForm
                v-slot="{ isLoading, hasErrors }"
                v-model="createFormData"
                name="usersForm"
                @submit="handleSubmit"
            >
                <b-row>
                    <b-col cols="12">
                        <vue-autosuggest
                            id="name"
                            v-model="createFormData.name"
                            :suggestions="usersOptions"
                            :limit="15"
                            :inputProps="{
                                id: 'autosuggest__input',
                                class: 'form-control',
                                placeholder: $t('common.crud.name'),
                            }"
                            @input="onSearchUsersByName"
                            @selected="setResult"
                        >
                            <template slot="before-input">
                                <label for="name">{{
                                    $t("common.crud.name")
                                }}</label>
                            </template>

                            <template slot-scope="{ suggestion }">
                                <span class="my-suggestion-item">{{
                                    suggestion.item.name
                                }}</span>
                            </template>
                        </vue-autosuggest>
                    </b-col>

                    <b-col cols="12" class="mt-2">
                        <formulateInput
                            id="password"
                            :label="$t('modules.users.password')"
                            name="password"
                            :placeholder="$t('modules.users.password')"
                            type="password"
                            validation="required|max:255,length"
                        />
                    </b-col>

                    <b-col cols="12" class="mt-2">
                        <formulateInput
                            id="password_confirmation"
                            :label="$t('modules.users.passwordConfirmation')"
                            name="password_confirmation"
                            :placeholder="
                                $t('modules.users.passwordConfirmation')
                            "
                            type="password"
                            validation="required|confirm:password"
                        />
                    </b-col>

                    <b-col cols="12" class="mt-2">
                        <formulateInput
                            :label="$t('common.status')"
                            name="status"
                            :placeholder="
                                $t('common.crud.select') +
                                ' ' +
                                $t('common.status').toLowerCase()
                            "
                            type="vue-select"
                            validation="optional|max:32,length"
                            :options="statusOptions"
                            @update:input="createFormData.status = $event"
                        />
                    </b-col>

                    <b-col cols="12" class="mt-2">
                        <formulateInput
                            :label="$tc('common.email.description', 1)"
                            name="email"
                            :placeholder="$t('common.email.address')"
                            type="text"
                            validation="optional|email"
                        />
                    </b-col>

                    <b-col cols="12" class="mt-2">
                        <b-form-group
                            :label="$tc('modules.roles.description', 2)"
                        >
                            <v-select
                                id="roles"
                                v-model="createFormData.roles"
                                :filterable="true"
                                :options="rolesOptions"
                                :reduce="(role) => role.id"
                                label="name"
                                :placeholder="
                                    $t('common.selectOneOrMany', {
                                        resource: $tc(
                                            'modules.roles.description',
                                            2
                                        ).toLowerCase(),
                                    })
                                "
                                required
                                multiple
                                :closeOnSelect="false"
                            >
                                <template slot="no-options">
                                    {{ $t("common.crud.optionsNotFound") }}
                                </template>
                            </v-select>
                        </b-form-group>
                    </b-col>
                </b-row>

                <formulateErrors />

                <b-row class="mt-3">
                    <b-col
                        v-if="!createdUser"
                        class="d-flex justify-content-center"
                    >
                        <formulateInput
                            :disabled="hasErrors || isLoading"
                            :label="
                                isLoading
                                    ? $t('common.crud.creating')
                                    : $t('common.crud.create')
                            "
                            inputClass="btn btn-primary"
                            type="submit"
                        />
                    </b-col>

                    <b-col
                        v-if="createdUser"
                        class="d-flex justify-content-center"
                    >
                        <formulateInput
                            :disabled="hasErrors || isLoading"
                            :label="$t('common.crud.edit')"
                            inputClass="btn btn-primary"
                            type="button"
                            @click="changeRoute(createdUser.id)"
                        />
                    </b-col>

                    <b-col class="d-flex justify-content-center">
                        <FormulateInput
                            :label="$t('common.crud.close')"
                            inputClass="btn btn-danger"
                            type="button"
                            @click="$refs['create-user'].hide()"
                        />
                    </b-col>
                </b-row>
            </formulateForm>
        </div>
    </b-modal>
</template>

<script>
import { BModal, BCol, BRow, BFormGroup } from "bootstrap-vue"
import { VueAutosuggest } from "vue-autosuggest"
import Api from "@/services/api"
import vSelect from "vue-select"
import toastMessages from "@/views/components/utilis-spa/utilities/ToastMessages"
import _ from "lodash"

export default {
    name: "UsersCreateSideBar",
    components: {
        BModal,
        BRow,
        BCol,
        VueAutosuggest,
        BFormGroup,
        vSelect,
    },
    data() {
        return {
            active: false,
            createFormData: {
                name: "",
                status: "",
                password: "",
                password_confirmation: "",
                email: "",
                roles: "",
            },
            createdUser: null,
            rolesOptions: [],
            statusOptions: [],
            usersOptions: [],
        }
    },
    mounted() {
        this.getStatus()
        this.getRoles()
    },

    methods: {
        onSearchUsersByName(text) {
            this.isLoading = true
            if (text) {
                this.search(text, this)
            }
        },
        search: _.debounce(async (search, vm) => {
            const users = await Api.findAll("users", {
                filter: {
                    search,
                },
            })

            // eslint-disable-next-line no-param-reassign
            vm.usersOptions = [
                {
                    data: users.data,
                },
            ]
        }, 350),

        setResult(selected) {
            if (!selected) return
            if (selected.item.id) this.changeRoute(selected.item.id)
        },
        changeRoute(value) {
            this.$router.push({
                name: "edit-users",
                params: { id: value },
            })
        },
        setAttributesDefaultValues() {
            this.createFormData.name = ""
            this.createFormData.status = ""
            this.createFormData.email = ""
            this.createFormData.password = ""
            this.createFormData.password_confirmation = ""
            this.createFormData.roles = ""
            this.usersOptions = []
        },

        async getRoles() {
            const response = await Api.findAll("roles", {
                fields: { roles: "name" },
            })

            this.rolesOptions = response.data
        },

        async getStatus() {
            const response = await Api.findAll("status", {
                fields: { status: "description" },
            })

            this.statusOptions = response.data.map(el => ({
                value: el.id,
                label: el.description,
            }))
        },

        async handleSubmit() {
            try {
                const userRequest = {
                    name: this.createFormData.name,
                    email: this.createFormData.email,
                    password: this.createFormData.password,
                    password_confirmation: this.createFormData
                        .password_confirmation,
                    status: this.createFormData.status
                        ? { id: this.createFormData.status, type: "status" }
                        : null,
                    roles:
                        this.createFormData.roles.length > 0
                            ? this.createFormData.roles.map(el => ({
                                  id: el,
                                  type: "roles",
                              }))
                            : [],
                }

                const response = await Api.create("users", {
                    ...userRequest,
                    include: "roles,status",
                })

                this.createdUser = response.data
                this.$emit("createUser", this.createdUser)

                this.$toast(toastMessages.getSuccessMessage())
                this.$refs["create-user"].hide()
            } catch (e) {
                this.createdUser = null
                this.$toast(toastMessages.getUnexpectedError())
                this.$formulate.handle(e, "usersForm")
            }
        },
    },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";

.autocomplete .form-group {
    margin-bottom: 0;
}

#password {
    -webkit-text-security: disc;
}
</style>
