var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',_vm._b({ref:"create-user",attrs:{"id":"create-users-modal","centered":"","title":_vm.$t('common.crud.newResource', {
            resource: _vm.$tc('modules.users.description', 1),
        }),"hideFooter":""},on:{"show":_vm.setAttributesDefaultValues}},'b-modal',_vm.$attrs,false),[_c('div',{staticClass:"ml-2 mr-2 mt-2"},[_c('formulateForm',{attrs:{"name":"usersForm"},on:{"submit":_vm.handleSubmit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var isLoading = ref.isLoading;
        var hasErrors = ref.hasErrors;
return [_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('vue-autosuggest',{attrs:{"id":"name","suggestions":_vm.usersOptions,"limit":15,"inputProps":{
                            id: 'autosuggest__input',
                            class: 'form-control',
                            placeholder: _vm.$t('common.crud.name'),
                        }},on:{"input":_vm.onSearchUsersByName,"selected":_vm.setResult},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var suggestion = ref.suggestion;
return [_c('span',{staticClass:"my-suggestion-item"},[_vm._v(_vm._s(suggestion.item.name))])]}}],null,true),model:{value:(_vm.createFormData.name),callback:function ($$v) {_vm.$set(_vm.createFormData, "name", $$v)},expression:"createFormData.name"}},[_c('template',{slot:"before-input"},[_c('label',{attrs:{"for":"name"}},[_vm._v(_vm._s(_vm.$t("common.crud.name")))])])],2)],1),_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('formulateInput',{attrs:{"id":"password","label":_vm.$t('modules.users.password'),"name":"password","placeholder":_vm.$t('modules.users.password'),"type":"password","validation":"required|max:255,length"}})],1),_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('formulateInput',{attrs:{"id":"password_confirmation","label":_vm.$t('modules.users.passwordConfirmation'),"name":"password_confirmation","placeholder":_vm.$t('modules.users.passwordConfirmation'),"type":"password","validation":"required|confirm:password"}})],1),_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('formulateInput',{attrs:{"label":_vm.$t('common.status'),"name":"status","placeholder":_vm.$t('common.crud.select') +
                            ' ' +
                            _vm.$t('common.status').toLowerCase(),"type":"vue-select","validation":"optional|max:32,length","options":_vm.statusOptions},on:{"update:input":function($event){_vm.createFormData.status = $event}}})],1),_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('formulateInput',{attrs:{"label":_vm.$tc('common.email.description', 1),"name":"email","placeholder":_vm.$t('common.email.address'),"type":"text","validation":"optional|email"}})],1),_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$tc('modules.roles.description', 2)}},[_c('v-select',{attrs:{"id":"roles","filterable":true,"options":_vm.rolesOptions,"reduce":function (role) { return role.id; },"label":"name","placeholder":_vm.$t('common.selectOneOrMany', {
                                    resource: _vm.$tc(
                                        'modules.roles.description',
                                        2
                                    ).toLowerCase(),
                                }),"required":"","multiple":"","closeOnSelect":false},model:{value:(_vm.createFormData.roles),callback:function ($$v) {_vm.$set(_vm.createFormData, "roles", $$v)},expression:"createFormData.roles"}},[_c('template',{slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t("common.crud.optionsNotFound"))+" ")])],2)],1)],1)],1),_c('formulateErrors'),_c('b-row',{staticClass:"mt-3"},[(!_vm.createdUser)?_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('formulateInput',{attrs:{"disabled":hasErrors || isLoading,"label":isLoading
                                ? _vm.$t('common.crud.creating')
                                : _vm.$t('common.crud.create'),"inputClass":"btn btn-primary","type":"submit"}})],1):_vm._e(),(_vm.createdUser)?_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('formulateInput',{attrs:{"disabled":hasErrors || isLoading,"label":_vm.$t('common.crud.edit'),"inputClass":"btn btn-primary","type":"button"},on:{"click":function($event){return _vm.changeRoute(_vm.createdUser.id)}}})],1):_vm._e(),_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('FormulateInput',{attrs:{"label":_vm.$t('common.crud.close'),"inputClass":"btn btn-danger","type":"button"},on:{"click":function($event){return _vm.$refs['create-user'].hide()}}})],1)],1)]}}]),model:{value:(_vm.createFormData),callback:function ($$v) {_vm.createFormData=$$v},expression:"createFormData"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }