<template>
    <div>
        <users-create
            id="create-users-modal"
            ref="modal"
            @createUser="getUsers"
        >
        </users-create>
        <b-card>
            <datatable-header
                :title="$tc('modules.users.description', 2)"
                :genericSearchEnabled="true"
                :searchTermPlaceHolder="$t('common.search')"
                @filterUpdated="onFilterUpdate"
            >
                <template #buttons>
                    <b-button
                        class="ml-2"
                        variant="primary"
                        style="background-color: #519bd6"
                        @click="
                            $root.$emit(
                                'bv::toggle::modal',
                                'create-users-modal'
                            )
                        "
                    >
                        {{
                            $t("common.crud.addNew", {
                                resource: $tc(
                                    "modules.users.description",
                                    1
                                ).toLowerCase(),
                            }).toString()
                        }}
                    </b-button>
                </template>

                <template #advancedSearch="{ expanded, onFilterChange }">
                    <DatatableFilter
                        :availableDatatableFilters="availableDatatableFilters"
                        :expanded="expanded"
                        @filterUpdated="onFilterUpdate"
                        @onFilterChange="onFilterChange"
                    ></DatatableFilter>
                </template>
            </datatable-header>
            <datatable
                :columns="columns"
                :isLoading="isLoading"
                :paginationOptions="{
                    enabled: true,
                    mode: 'pages',
                    perPage: dataTableOptions.pagination.perPage,
                }"
                :rows="rows"
                :rtl="direction"
                :searchOptions="{
                    enabled: true,
                    externalQuery: searchTerm,
                }"
                :totalRows="dataTableOptions.pagination.totalRows"
                mode="remote"
                theme="my-theme"
                @on-row-click="onRowClick"
                @on-page-change="handleChangePage"
                @onPageSizeChange="handlePageSizeChange"
            >
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field === 'status'">
                        <b-badge
                            v-if="props.row.status"
                            :variant="
                                statusVariant(props.row.status.description)
                            "
                        >
                            {{ props.row.status.description }}
                        </b-badge>
                    </span>
                    <span v-else-if="props.column.field === 'roles'">
                        <span v-for="role in props.row.roles" :key="role.id">
                            <b-badge>
                                {{ role.name }}
                            </b-badge>
                        </span>
                    </span>
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </datatable>
        </b-card>
    </div>
</template>

<script>
import { BBadge, BCard, BButton } from "bootstrap-vue"
import store from "@/store/index"
import Datatable from "@/views/components/utilis-spa/datatable/Datatable.vue"
import DatatableFilterUtils from "@/views/components/utilis-spa/datatable/datatableFilterUtil"
import Api from "@/services/api/index"
import UsersCreate from "@/views/app/management/users/users-create/UsersCreate.vue"
import DatatableHeader from "@/views/components/utilis-spa/datatable/DatatableHeader.vue"
import DatatableFilter from "@/views/components/utilis-spa/datatable/DatatableFilter.vue"

export default {
    components: {
        DatatableFilter,
        DatatableHeader,
        UsersCreate,
        BCard,
        BBadge,
        BButton,
        Datatable,
    },
    data() {
        return {
            dataTableOptions: {
                pagination: {
                    perPage: 5,
                    totalRows: 0,
                },
            },
            isLoading: true,
            dir: false,
            isFilterExpanded: false,
            columns: [
                {
                    label: this.$t("common.crud.id"),
                    field: "id",
                },
                {
                    label: this.$t("common.crud.name"),
                    field: "name",
                },
                {
                    label: this.$tc("common.email.description", 1),
                    field: "email",
                },
                {
                    label: this.$tc("modules.people.description", 2),
                    field: "roles",
                },
                {
                    label: this.$t("common.status"),
                    field: "status",
                },
                {
                    label: this.$t("common.crud.createdAt"),
                    field: "createdAt",
                    formatFn: this.formatDateTime,
                },
                {
                    label: this.$t("common.crud.updatedAt"),
                    field: "updatedAt",
                    formatFn: this.formatDateTime,
                },
            ],
            rows: [],
            searchTerm: "",
            availableDatatableFilters: {},
            apiFilter: null,
            nameFilter: "",
        }
    },
    computed: {
        statusVariant() {
            const statusColor = {
                Ativo: "light-primary",
                Inativo: "light-danger",
            }

            return status => statusColor[status]
        },
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
    },
    created() {
        this.getUsers()
        this.getAvailableFilters()
    },
    methods: {
        formatDateTime(date) {
            // return date.format('L LT')
            // return this.$d(date, 'short')
            if (date == null) return

            // eslint-disable-next-line consistent-return
            return this.$d(new Date(date), "dateTime")
        },
        handleChangePage(page) {
            this.isLoading = true
            this.getUsers({ selectedPage: page.currentPage })
        },
        handlePageSizeChange(pageSize) {
            this.dataTableOptions.pagination.perPage = pageSize
            this.getUsers()
        },
        async getUsers(options = {}) {
            try {
                this.isLoading = true
                const { pagination: paginationOptions } = this.dataTableOptions
                const { selectedPage } = options

                const usersResponse = await Api.findAll("users", {
                    // RELATED RESOURCES
                    include: "roles,status",

                    // SPARSE FIELDSETS
                    fields: {
                        roles: "name",
                        status: "description",
                    },

                    // FILTERS
                    filter: this.apiFilter,

                    // PAGINATION
                    page: {
                        size: paginationOptions.perPage,
                        number: selectedPage || 1,
                    },
                })

                this.rows = usersResponse.data
                const paginationMetaData = usersResponse.meta.page
                this.dataTableOptions.pagination.totalRows =
                    paginationMetaData.total
            } finally {
                this.isLoading = false
            }
        },

        async getAvailableFilters() {
            this.availableDatatableFilters.name = DatatableFilterUtils.createFilter.text(
                "name",
                {
                    label: this.$t("common.crud.name"),
                    placeholder: this.$t("common.searchBy", {
                        field: this.$t("common.crud.name").toLowerCase(),
                    }),
                }
            )

            this.availableDatatableFilters.email = DatatableFilterUtils.createFilter.text(
                "email",
                {
                    label: this.$tc("common.email.description", 1),
                    placeholder: this.$t("common.searchBy", {
                        field: this.$tc(
                            "common.email.description",
                            1
                        ).toLowerCase(),
                    }),
                }
            )

            const rolesResponse = await Api.findAll("roles", {
                fields: { roles: "name" },
            })

            this.availableDatatableFilters.roles = DatatableFilterUtils.createFilter.list(
                "roles",
                rolesResponse.data.map(role => ({
                    value: role.id,
                    label: role.name,
                })),
                {
                    filterAttribute: "id",
                    label: this.$tc("modules.roles.description", 1),
                    placeholder: this.$t("common.searchBy", {
                        field: this.$tc(
                            "modules.roles.description",
                            1
                        ).toLowerCase(),
                    }),
                }
            )
        },
        onRowClick(params) {
            this.$router.push({
                name: "edit-users",
                params: { id: params.row.id },
            })
        },
        onFilterExpandedChange() {
            this.isFilterExpanded = !this.isFilterExpanded
        },
        onFilterUpdate(updatedFilter) {
            this.apiFilter = DatatableFilterUtils.serializer.datatableFilterToJsonApi(
                updatedFilter
            )
            this.getUsers()
        },
    },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.skeletonTable {
    position: relative;
    top: -50px;
    margin: 0px 20px;
}

.vgt-inner-wrap.is-loading {
    opacity: 1;
}
</style>
